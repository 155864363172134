.stacked-wraper{
    display: flex;
    justify-content: center;
    align-items: center;
}
.stacked-wraper h1{
  font-family: 'widy-regular' !important;
  font-size: 30px !important;
  color: #9c2ed2 !important;
  padding: 20px 0;
}
.stack-card{
    border: 1px solid #783dca;
    width: 50%;
    height: 600px;
    margin: 20px;
    border-radius: 2rem;
    background: #121212;
}
.stake-button{
    background-color: #3e0daa !important;
    border-radius: 50px !important;
    padding: 8px 20px !important;
    margin: 100px 10px !important;
    font-family:'Neue-Haas-Grotesk ' !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    padding-top: 10px !important;
    color: #f0ebeb !important;
    text-transform: none !important;
}
.stake-button:hover{
    background-color: #000 !important;
    color: #3e0daa !important;
}
.secondCard-button{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -75px;
    margin-bottom: -10px;
}
.secondCard-text{
    color:#cecbd5;
    font-family:'Neue-Haas-Grotesk';
    font-size:18px !important;
    padding-top: 55px;
    font-weight: 500;

}

.mySwiper{
    padding-left: 30px;
    padding-right: 30px; 
}

.mySwiperSlide{
    padding: 10;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.two-slide-img{
    display: flex;
    align-items: center;
    justify-content: center;
}
@media only screen and (max-width:979px){
    .stacked-wraper{
      flex-direction: column;
    }
    .stack-card{
        border: 1px solid #57F287;
        width: 95%;
        margin: 20px;
        border-radius: 2rem;
    }
    .secondCard-button{
        flex-direction: column;
    }
   
   .unstack-btn{
       margin-top: -40px !important;
   }
.stake-button{
    margin: 50px 10px;
}
.secondCard-text{
  margin-bottom: 50px;

}
}

/* swiper slide// */
.swiper {
    width: 90%;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #121212;
  
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin: 10px;
  }
  
  .swiper {
    margin-left: auto;
    margin-right: auto;
  }
  
